<template>
<div class="logCell">
  <div class="header">{{title}}</div>
  <div :class="contentClass" class="content">{{content}}</div>
</div>
</template>

<script>
export default {
  name: 'logCell',
  props:{
    contentClass:{
      default:'',
    },
    title:{
      default:'',
    },
    content:{
      default:'',
    },

  },
}
</script>

<style lang="scss" scoped>

.logCell {
  min-height: 20px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;


  .header {
    width: 90px;
    word-break: break-all;
    text-align: center;
    min-height: 20px;
    line-height: 20px;
    font-size: 13px;
  }

  .content {
    width: calc(100% - 100px);
    margin-left: 10px;
    word-break: break-all;
    text-align: left;
    min-height: 20px;
    line-height: 20px;
    font-size: 13px;
    border-bottom: 1px solid #cccccc;
  }
}



.color_medium {
  color: #5fb0ed !important;
}

.color_warning {
  color: #e68b35 !important;
}

.color_danger {
  color: #e44545 !important;
}

.color_default {
  color: #666 !important;
}

.color_principal {
  color: #333 !important;
}


</style>
