<template>
  <div class="inPutCell">
    <div class="header">{{ title }}</div>
    <el-input
        class="content"
        type="textarea"
        :autosize="{ minRows: 2}"
        placeholder="请输入内容"
        v-model="textarea"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'inPutCell',
  props: {
    title: {
      default: ''
    },
    content: {
      default: ''
    }
  },
  watch:{
    content:{
      handler(value) {
        this.textarea = value;
      },
    },
  },
  data() {
    return {
      textarea: ''
    }
  },
  methods:{
    getTextarea:function() {
      return this.textarea;
    }
  }
}
</script>

<style lang="scss" scoped>

.inPutCell {
  min-height: 20px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;


  .header {
    width: 90px;
    word-break: break-all;
    text-align: center;
    min-height: 20px;
    line-height: 20px;
    font-size: 13px;
  }

  .content {
    width: calc(100% - 100px);
    margin-left: 10px;
    word-break: break-all;
    text-align: left;
    min-height: 20px;
    line-height: 20px;
    font-size: 13px;
  }
}

</style>
