<template>
  <div class="alertView">
    <div>
      <div class="title">警报内容</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'content') == false ? '-' : dataInfo['content'] }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">警报时间</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'startTs') == false ? '-' : formatDate(dataInfo.startTs) }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">警报类型</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'type') == false ? '-' : alertType(dataInfo) }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">警报等级</div>
      <div :class="alertLevel(dataInfo.severity).color" class="content">
        {{ $valueIsExist(dataInfo, 'severity') == false ? '-' : alertLevel(dataInfo.severity).label }}
      </div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">所属科室</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'tenantName') == false ? '-' : dataInfo['tenantName'] }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">警报位置</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'address') == false ? '-' : dataInfo['address'] }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">警报状态</div>
      <div :class="alertStatus(dataInfo).color" class="content">{{ alertStatus(dataInfo).label }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">处理人员</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'userName') == false ? '-' : dataInfo['userName'] }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">处理时间</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'clearTs') == false ? '-' : formatDate(dataInfo.clearTs) }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">警报原因</div>
      <div class="content">{{ $valueIsExist(dataInfo, 'cause') == false ? '-' : dataInfo['cause'] }}</div>
      <div style="clear: both"></div>
    </div>
    <div v-if="type==1" @click.stop="taval()">
      <div class="title color_medium">查看轨迹</div>
      <div class="content"></div>
      <div style="clear: both"></div>
    </div>
    <div @click.stop="location()">
      <div class="title color_medium">查看位置</div>
      <div class="content"></div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alertView',
  props: {
    alertTypes:{
      type:Array,
      default:() => {
        return [];
      }
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      type:1,
      alertTypeInfo:{},
    }
  },
  mounted() {
    let type = 1;
    let query = this.$route.query
    if (this.$valueIsExist(query, 'alertType')) {
      type = query['alertType']
    }
    this.type = type;
  },
  beforeRouteLeave(to, from, next) {
    let leave = () => {
      if (this.showDetail) {
        this.showDetail = false
        next(false)
        return
      }
      next(true)
    }
    leave()
  },
  methods: {
    location: function() {
      this.$emit('location', this.dataInfo)
    },
    taval: function() {
      this.$emit('showTraval', this.dataInfo)
    },
    alertStatus: function(item) {
      if (typeof item['status'] == 'undefined' || item['status'] == null) {
        return {
          color: 'color_warning',
          label: '未处理'
        }
      }
      if (item['status'] == 1) {
        return {
          color: 'color_warning',
          label: '未处理'
        }
      }
      return {
        color: 'color_medium',
        label: '已处理'
      }
    },
    alertLevel: function(value) {
      switch (value) {
        case 1:
          return {
            label: '严重',
            color: 'color_danger',
            type: 'danger'
          }
        case 2:
          return {
            label: '重要',
            color: 'color_warning',
            type: 'warning'
          }
        case 3:
          return {
            label: '普通',
            color: 'color_primary',
            type: 'primary'
          }
        case 4:
          return {
            label: '提醒',
            color: 'color_info',
            type: 'info'
          }
        default:
          return {
            label: '未知',
            color: 'color_default',
            type: 'info'
          }
      }
    },
    alertType: function(item) {
      let type = 0
      if (typeof item['type'] != 'undefined' && item['type'] != null) {
        type = item['type']
      }

      let findList = (list, type) => {
        if (list.length == 0) {
          return {
            result:false,
            msg:'',
          }
        }
        let children = [];
        for (let i = 0; i < list.length; i++) {
          let info = list[i];
          if (info.id == type) {
            return {
              result:true,
              msg:info['name']
            }
          }
          children = children.concat(info['children'])
        }
        return findList(children, type);
      }


      let key = type + '';
      if (this.$valueIsExist(this.alertTypeInfo, key)) {
        return this.alertTypeInfo[key]
      }
      let res = findList(this.alertTypes, type);
      this.alertTypeInfo[key] = res.msg
      return res.msg
    },
  }
}
</script>

<style lang="scss" scoped>

.alertView {

  .title {
    width: 60px;
    text-align: right;
    height: 30px;
    line-height: 25px;
    font-size: 13px;
    float: left;
  }

  .content {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 80px);
    text-align: right;
    line-height: 25px;
    font-size: 13px;
    text-align: left;
    float: right;
    word-break: break-all;
  }
}


.color_medium {
  color: #5fb0ed !important;
}

.color_warning {
  color: #e68b35 !important;
}

.color_danger {
  color: #e44545 !important;
}

.color_default {
  color: #666 !important;
}

.color_principal {
  color: #333 !important;
}


</style>
