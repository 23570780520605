let mixin = {
  data: function() {
    return {
      alertType:1,
      page: 1,
      pageSize: 50,
      totalCount: 0,
      inPut: '',
      dialogVisible: false,
      dialogType: '',
      loadOrg: false,
      loadAlertType: false,
      loadAlertStatus: false,
      orgList: [],
      selectOrgList: [],
      alertTypes: [],
      selectAlertTypes: [],
      alertStatus: [],
      selectStartus: [],
      loadTableData: false,
      tableData: []
    }
  },
  mounted() {
    let alertType = 1;
    let query = this.$route.query
    if (this.$valueIsExist(query, 'alertType')) {
      alertType = query['alertType']
    }
    this.alertType = alertType;

    this.$nextTick(() => {
      this.getAllData()
    })
  },
  props: {

  },
  computed: {
  },
  methods: {
    currentChange: function(index) {
      this.page = index
      this.loadAlertTable(false, true)
    },
    getAllData: function() {
      this.loadOrg = true

      Promise.all([this.getAlerTypeList(), this.getOrg()]).then(res => {
        let typeRes = res[0]
        this.baseOptions.queryAlarmTypeOptions = typeRes.list

        let orgRes = res[1]
        this.baseOptions.tenantOptions = orgRes.list

        this.loadAlertStatus = false
      })
      this.loadAlertTable(true, true)
    },
    getAlerTypeList: function() {
      return new Promise(resolve => {
        this.orgList = []
        this.$api.assetModule.getAlertTypeList({ queryType: this.alertType }).then(res => {

          let list = []
          if (typeof res.data != 'undefined' && res.data != null) {
            list = res.data
          }

          let topInfo = { id: '-1', name: '全部', children: [] }
          let newList = [topInfo]
          for (let i = 0; i < list.length; i++) {
            let info = list[i]
            topInfo.children.push({
              pid:'-1',
              id: info['type'],
              name: info['content'],
              children: []
            })
          }

          resolve({ list: newList, selectList: newList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    // 获取子机构科室
    getOrg: function() {
      let getData = () => {
        return new Promise(resolve => {
          let orgInfo = this.$store.getters.orgInfo
          if (orgInfo.length > 0) {
            let selectList = []
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < orgInfo.length; i++) {
              let org = orgInfo[i]
              if (org.id == tenantId) {
                selectList.push(org)
                break
              }
            }
            resolve({ list: orgInfo, selectList: selectList })
            return
          }

          this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
            let jsonStr = JSON.stringify(res.data)
            jsonStr = jsonStr.replace(/title/g, 'name')
            jsonStr = jsonStr.replace(/child/g, 'children')

            let list = []
            let selectList = []

            try {
              list = JSON.parse(jsonStr)
              let userInfo = this.$store.getters.userInfo
              let tenantId = userInfo.tenantId
              for (let i = 0; i < list.length; i++) {
                let org = list[i]
                if (org.id == tenantId) {
                  selectList.push(org)
                  break
                }
              }
            } catch (e) {

            }
            this.$store.commit('setOrgInfo', list)
            resolve({ list: this.$store.getters.orgInfo, selectList: selectList })
          }).catch(e => {
            resolve({ list: [], selectList: [] })
          })
        })
      }

      return getData()
    },
    reset: function() {
      this.page = 1
      this.pageSize = 30
    },
    loadAlertTable: function(reset, loading) {

      if (reset) {
        this.reset()
      }
      if (loading) {
        this.loadTableData = true
      }

      let loadData = () => {
        let tenantId = ''

        if (typeof this.query['tenantIds'] != 'undefined') {
          tenantId = this.query['tenantIds']
        }


        let type = ''
        if (typeof this.query['queryAlarmType'] != 'undefined') {
          type = this.query['queryAlarmType']
        }
        if (type < 0) {
          type = ''
        }

        let status = ''
        for (let i = 0; i < this.selectStartus.length; i++) {
          status = status + (status == '' ? '' : ',') + this.selectStartus[i].id
        }
        this.$api.assetModule.getAlertist({
          deleted: 0,
          query: this.inPut,
          page: this.page,
          pageSize: this.pageSize,
          tenantId: tenantId,
          queryType: this.alertType,
          type: type,
          status: status
        }).then(res => {
          if (res.code == 200) {
            this.tableData = res.data
            this.totalCount = res.totalCount
          }
          this.loadTableData = false

        }).catch(e => {
          this.loadTableData = false
        })
      }

      loadData()

    }
  }
}

export default mixin
