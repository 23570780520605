import { render, staticRenderFns } from "./alertView.vue?vue&type=template&id=4a7c2a10&scoped=true&"
import script from "./alertView.vue?vue&type=script&lang=js&"
export * from "./alertView.vue?vue&type=script&lang=js&"
import style0 from "./alertView.vue?vue&type=style&index=0&id=4a7c2a10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a7c2a10",
  null
  
)

export default component.exports