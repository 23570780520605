<template>
  <div class="alertMessageBGView">
    <filter-panel_-v2 ref="filter" v-model="query" :layout="filterLayout" :options="baseOptions"
                      @searchEvent="startSearch"
    >
    </filter-panel_-v2>

    <alert-message-table
        :alert-types="alertTypes"
        @taval="taval"
        @rowclick="rowclick"
        :loading="loadTableData"
        :table-data="tableData"
        :total-count="totalCount"
        height="calc(100% + 30px)"
        class="alertList"
    ></alert-message-table>


    <pagination-vue @current-change="currentChange"
                    :page-size="pageSize"
                    :pager-count="5"
                    :total-count="totalCount"
                    :current-page="page"
    ></pagination-vue>

    <alert-message-detail-log :alert-types="alertTypes" @updateSuccess="loadAlertTable(false, false)" ref="detailLog"
    ></alert-message-detail-log>
    <patient-traval ref="traval"></patient-traval>
  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import VStack from '@/components/vStack/vStack'
import alertMessageMixin from '@/views/alertMessge/mixin/alertMessageMixin'
import VueTree from '@/components/tree/vueTree'
import AlertMessageTable from '@/views/alertMessge/alertMessageTable'
import AlertMessageDetailLog from '@/views/alertMessge/alertMessageDetailLog/alertMessageDetailLog'
import PatientTraval from '@/views/alertMessge/traval/patientTraval'
import PaginationVue from '@/components/pagination/paginationVue'
import FilterPanel_V2 from '@/components/filterPanel/filterPanel_V2'

export default {
  name: 'alertMessage',
  components: {
    FilterPanel_V2,
    PaginationVue,
    PatientTraval,
    AlertMessageDetailLog,
    AlertMessageTable,
    VueTree,
    VStack,
    HStack
  },
  mixins: [alertMessageMixin],
  data() {
    return {
      query: {},
      baseOptions: {
        tenantOptions: [],
        queryAlarmTypeOptions: [],
      },
      filterLayout: [
        {
          label: '科室',
          key: 'tenantIds',
          optionKey: 'tenantOptions',
          childKey: 'children',
          valueKey: 'id',
          labelKey: 'name',
          searchKey: 'name',
          filterablePlaceholder: '搜索科室',
          defauleValue: []
        },
        {
          label: '警报分类',
          key: 'queryAlarmType',
          optionKey: 'queryAlarmTypeOptions',
          childKey: 'children',
          valueKey: 'id',
          labelKey: 'name',
          searchKey: 'name',
          filterablePlaceholder: '搜索警报分类',
          defauleValue: []
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      let info = this.$store.getters.alertInfo
      if (typeof info['id'] == 'undefined' || info['id'] == null) {
        return
      }
      this.rowclick(info)
      this.$store.commit('setAlertInfo', {})
    })
  },
  watch: {
    selectOrgList: {
      handler(value) {
        this.startSearch()
      },
      deep: true
    },
    selectAlertTypes: {
      handler(value) {
        this.startSearch()
      },
      deep: true
    },
    selectStartus: {
      handler(value) {
        this.startSearch()
      },
      deep: true
    }
  },
  methods: {
    taval: function(item) {
      if (typeof this.$refs['traval'] == 'undefined' || this.$refs['traval'] == null) {
        return
      }
      console.log(item)
      item['alertType'] = this.alertType
      item['name'] = item['content']
      this.$refs['traval'].showOrHidden(true, item)
    },
    rowclick: function(item) {
      if (typeof this.$refs['detailLog'] == 'undefined' || this.$refs['detailLog'] == null) {
        return
      }
      this.$refs['detailLog'].showOrHidden(true)
      this.$refs['detailLog'].getAlertMessageDetail(item)
    },
    startSearch: function(res) {
      for (const resKey in res) {
        this.query[resKey] = res[resKey]
      }
      this.loadAlertTable(true, true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.alertMessageBGView {
  width: 100%;
  height: 100%;
  background-color: white;

  .searchFillter {
    width: 100%;
    background-color: white;

    .searchFillterBGView {
      width: calc(100% - 60px);
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      margin-right: 10px;

      .flllterChoose {
        float: left;
        width: 100%;
        margin-top: 5px;


        .checkAssetChooseSelect {
          float: right;
          width: calc(100% - 95px);
          margin-left: 5px;
          overflow: scroll;
          max-height: 30px;


          .checkAssetChooseSelectItem {
            padding-left: 5px;
            padding-right: 5px;
            word-wrap: break-word;
            margin-left: 5px;
            font-size: 13px;
            color: $color_primary;
            line-height: 28px;
            min-height: 28px;
            border-radius: 5px;
            margin-bottom: 2px;
          }
        }

      }

    }

    .fillterTitle {
      text-align: center;
      width: 70px;
      float: left;
      font-size: 14px;
      color: white;
      border-radius: 5px;
      background-color: $color_primary;
      border: none;
    }
  }


  .alertList {
    width: 100%;
    height: calc(100% - 195px);
  }

}

</style>
