<template>
  <el-dialog
      title="警报详情"
      :visible.sync="dialogVisible"
      width="90%"
  >
    <div class="detailLog">
      <log-cell title="警报内容" :content="content"></log-cell>
      <log-cell title="报警时间" :content="startTs"></log-cell>
      <log-cell title="报警类型" :content="type"></log-cell>
      <log-cell title="报警等级" :content-class="severity.color" :content="severity.label"></log-cell>
      <log-cell title="报警科室" :content="tenantName"></log-cell>
      <log-cell title="报警位置" :content="address"></log-cell>
      <log-cell title="报警状态" :content-class="status.color" :content="status.label"></log-cell>
      <log-cell title="处理人员" :content="userName"></log-cell>
      <log-cell title="处理时间" :content="clearTs"></log-cell>
      <in-put-cell ref="cause" :content="cause" title="警报原因"></in-put-cell>
      <in-put-cell ref="describe" :content="describe" title="警报描述"></in-put-cell>
      <div v-show="!status.result" class="footer">
        <el-button @click="dialogVisible=false">返回</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import LogCell from '@/views/alertMessge/logCell'
import InPutCell from '@/views/alertMessge/inPutCell'

export default {
  name: 'alertMessageDetailLog',
  components: { InPutCell, LogCell },
  props: {
    alertTypes:{
      type:Array,
      default:() => {
        return [];
      }
    },
  },
  computed: {
    content() {
      let res = this.getValueByKey('content')
      return res.result ? res.value : ''
    },
    startTs() {
      let res = this.getValueByKey('startTs')
      return res.result ? this.formatDate(res.value) : ''
    },
    type() {
      return this.alertType(this.alertMessage)
    },
    severity() {
      let res = this.getValueByKey('severity')
      if (res.result == false) {
        return {
          label: '',
          color: '',
          type: ''
        }
      }
      return this.alertLevel(res.value)
    },
    tenantName() {
      let res = this.getValueByKey('tenantName')
      return res.result ? res.value : ''
    },
    address() {
      let res = this.getValueByKey('address')
      return res.result ? res.value : ''
    },
    status() {
      return this.alertStatus(this.alertMessage)
    },
    userName() {
      let res = this.getValueByKey('userName')
      return res.result ? res.value : ''
    },
    clearTs() {
      let res = this.getValueByKey('clearTs')
      return res.result ? this.formatDate(res.value) : ''
    },
    cause() {
      let res = this.getValueByKey('cause')
      return res.result ? res.value : ''
    },
    describe() {
      let res = this.getValueByKey('describe')
      return res.result ? res.value : ''
    }
  },
  data() {
    return {
      alertTypeInfo:{},
      dialogVisible: false,
      alertMessage: {}
    }
  },
  methods: {
    getAlertMessageDetail: function(config) {
      let findAlarmById = () => {
        return new Promise(resolve => {
          if (typeof config['id'] == 'undefined' || config['id'] == null) {
            resolve({ result: false })
            return
          }
          this.$api.assetModule.findAlarmById({ id: config['id'] }).then(res => {
            if (res.code != 200) {
              resolve({ result: false })
              return ''
            }
            resolve({ result: true, data: res.data })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }
      findAlarmById().then(res => {
        if (res.result == false) {
          return
        }
        this.alertMessage = res.data
      })
    },
    alertStatus: function(item) {
      if (typeof item['status'] == 'undefined' || item['status'] == null) {
        return {
          result: false,
          color: 'color_warning',
          label: '未处理'
        }
      }
      if (item['status'] == 1) {
        return {
          result: false,
          color: 'color_warning',
          label: '未处理'
        }
      }
      return {
        result: true,
        color: 'color_medium',
        label: '已处理'
      }
    },
    alertLevel: function(value) {
      switch (value) {
        case 1:
          return {
            label: '严重',
            color: 'color_danger',
            type: 'danger'
          }
        case 2:
          return {
            label: '重要',
            color: 'color_warning',
            type: 'warning'
          }
        case 3:
          return {
            label: '普通',
            color: 'color_primary',
            type: 'primary'
          }
        case 4:
          return {
            label: '提醒',
            color: 'color_info',
            type: 'info'
          }
        default:
          return {
            label: '未知',
            color: 'color_default',
            type: 'info'
          }
      }
    },
    alertType: function(item) {
      let type = 0
      if (typeof item['type'] != 'undefined' && item['type'] != null) {
        type = item['type']
      }

      let findList = (list, type) => {
        if (list.length == 0) {
          return {
            result:false,
            msg:'',
          }
        }
        let children = [];
        for (let i = 0; i < list.length; i++) {
          let info = list[i];
          if (info.id == type) {
            return {
              result:true,
              msg:info['name']
            }
          }
          children = children.concat(info['children'])
        }
        return findList(children, type);
      }


      let key = type + '';
      if (this.$valueIsExist(this.alertTypeInfo, key)) {
        return this.alertTypeInfo[key]
      }
      let res = findList(this.alertTypes, type);
      this.alertTypeInfo[key] = res.msg
      return res.msg
    },
    getValueByKey: function(key) {
      if (typeof this.alertMessage[key] == 'undefined' || this.alertMessage[key] == null) {
        return {
          result: false
        }
      }
      return {
        result: true,
        value: this.alertMessage[key]
      }
    },
    showOrHidden: function(show) {
      this.dialogVisible = show
    },
    submit() {
      this.$confirm('是否处理该警报', '', {
        confirmButtonText: '确定',
        customClass: 'confirmBox',//弹窗样式
        callback: action => {
          this.updateAlertMessage()
        }
      })
    },
    updateAlertMessage: function() {
      if (typeof this.alertMessage['id'] == 'undefined' || this['alertMessage'] == null) {
        return
      }

      let describe = ''
      if (typeof this.$refs['describe'] != 'undefined' && this.$refs['describe'] != null) {
        describe = this.$refs['describe'].getTextarea()
      }

      let cause = ''
      if (typeof this.$refs['cause'] != 'undefined' && this.$refs['cause'] != null) {
        cause = this.$refs['cause'].getTextarea()
      }

      let id = this.alertMessage['id']
      this.$api.assetModule.disposeAlarm({ id: id, cause: cause, describe: describe }).then(res => {
        if (res.code != 200) {
          this.$message.error('提交失败')
        } else {
          this.$message.success('提交成功')
          this.dialogVisible = false;
          this.$emit('updateSuccess', {});
        }
      }).catch(e => {
        this.$message.error('提交失败')
      })

    }
  }
}
</script>

<style>
.el-message-box {
  width: 80%;
}
</style>

<style lang="scss" scoped>

.detailLog {
  border-top: 1px solid #cccccc;
  width: 100%;
  height: 500px;
  padding-top: 10px;

  .footer {
    width: 100%;
    height: 40px;
    text-align: center;
    margin-top: 20px;
  }
}


::v-deep .el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
.el-dialog .el-dialog__body{
  flex:1;
  overflow: auto;
}



</style>
