<!--<template>-->
<!--  <div class="alertMessageTable">-->
<!--    <div class="alertMessageHeader">-->
<!--      设备警报({{ totalCount }}条)-->
<!--    </div>-->
<!--    <el-table-->
<!--        border-->
<!--        stripe-->
<!--        v-loading="loading"-->
<!--        :height="height"-->
<!--        :data="tableData"-->
<!--        class="checkAssetList" @row-click="rowClick"-->
<!--    >-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          fixed-->
<!--          prop="content"-->
<!--          label="警报内容"-->
<!--          width="130px"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          prop="startTs"-->
<!--          width="180px"-->
<!--          label="时间"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ formatDate(scope.row.startTs) }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="type"-->
<!--          align="center"-->
<!--          label="类型"-->
<!--          width="120px"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ alertType(scope.row) }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          prop="severity"-->
<!--          label="等级"-->
<!--          width="80px"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <span :class="alertLevel(scope.row.severity).color">{{ alertLevel(scope.row.severity).label }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          prop="tenantName"-->
<!--          label="科室"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="address"-->
<!--          align="center"-->
<!--          min-width="150px"-->
<!--          label="警报位置"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="status"-->
<!--          align="center"-->
<!--          label="状态"-->
<!--          width="80px"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <span :class="alertStatus(scope.row).color">{{ alertStatus(scope.row).label }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="userName"-->
<!--          align="center"-->
<!--          label="处理人"-->
<!--          width="120px"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="clearTs"-->
<!--          align="center"-->
<!--          label="处理时间"-->
<!--          width="180px"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ formatDate(scope.row.clearTs) }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="cause"-->
<!--          align="center"-->
<!--          label="警报原因"-->
<!--          width="120px"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          header-align="center"-->
<!--          prop="traval"-->
<!--          align="center"-->
<!--          label="轨迹"-->
<!--          width="120px"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <el-button type="text" @click.stop="taval(scope.row)">查看轨迹</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
<!--  </div>-->
<!--</template>-->

<template>
  <div class="alertMessageTable">
    <div class="alertMessageHeader">
      设备警报({{ totalCount }}条)
    </div>
    <el-table
        :show-header="false"
        border
        stripe
        v-loading="loading"
        :height="height"
        :data="tableData"
        class="checkAssetList" @row-click="rowClick"
    >
      <el-table-column>
        <template slot-scope="scope">
          <alert-view :alert-types="alertTypes" @location="location" @showTraval="taval" :data-info="scope.row"></alert-view>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="90%" title="" :visible.sync="showDetail">
      <div style="width: 100%;height: 600px">
        <search-device-detail :type="alertType" :device-detail="selectItem"></search-device-detail>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import AlertView from '@/views/alertMessge/alertView'
import SearchDeviceDetail from '@views/searchDevice/searchDeviceDetail/searchDeviceDetail'

export default {
  name: 'alertMessageTable',
  components: { SearchDeviceDetail, AlertView },
  props: {
    alertTypes: {
      type: Array,
      default: () => {
        return []
      }
    },
    totalCount: {
      default: 0
    },
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'calc(100% - 190px)'
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selectItem: {},
      showDetail: false,
      alertType: 1,
    }
  },
  mounted() {
    this.$nextTick(() => {
      let type = ''
      let query = this.$route.query
      if (typeof query['alertType'] != 'undefined') {
        type = query['alertType']
      }
      this.alertType = type;
    })
  },
  computed: {},
  methods: {
    location: function(item) {
      if (this.alertType == 5) {
        this.showDetail = true
        this.selectItem = JSON.parse(JSON.stringify(item));
        return;
      }

      let getPost = {
        id: item['deviceId']
      }
      this.$api.deviceManager.getDeviceDetail(getPost).then(res => {
        this.showDetail = true
        this.selectItem = res.data;
      }).catch(e => {
        this.showDetail = false
      })
    },
    alertStatus: function(item) {
      if (typeof item['status'] == 'undefined' || item['status'] == null) {
        return {
          color: 'color_warning',
          label: '未处理'
        }
      }
      if (item['status'] == 1) {
        return {
          color: 'color_warning',
          label: '未处理'
        }
      }
      return {
        color: 'color_medium',
        label: '已处理'
      }
    },
    alertLevel: function(value) {
      switch (value) {
        case 1:
          return {
            label: '严重',
            color: 'color_danger',
            type: 'danger'
          }
        case 2:
          return {
            label: '重要',
            color: 'color_warning',
            type: 'warning'
          }
        case 3:
          return {
            label: '普通',
            color: 'color_primary',
            type: 'primary'
          }
        case 4:
          return {
            label: '提醒',
            color: 'color_info',
            type: 'info'
          }
        default:
          return {
            label: '未知',
            color: 'color_default',
            type: 'info'
          }
      }
    },
    rowClick: function(item) {
      this.$emit('rowclick', item)
    },
    taval: function(item) {
      this.$emit('taval', item)
    }
  }
}
</script>

<style lang="scss" scoped>

.alertMessageTable {
  .alertMessageHeader {
    width: 100%;
    height: 40px;
    background-color: white;
    line-height: 40px;
    text-align: center;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
  }
}

.color_medium {
  color: #5fb0ed !important;
}

.color_warning {
  color: #e68b35 !important;
}

.color_danger {
  color: #e44545 !important;
}

.color_default {
  color: #666 !important;
}

.color_principal {
  color: #333 !important;
}

</style>
